'use client';
import { useEffect } from 'react';

import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
`;

const ErrorTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
`;

const ErrorMessage = styled.p`
  margin-bottom: 1rem;
  color: #4b5563;
`;

const RetryButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #fa6831;
  color: white;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

/**
 * @todo translate
 */
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <ErrorContainer>
      <ErrorTitle>Something went wrong!</ErrorTitle>
      <ErrorMessage>
        {error.message || 'An unexpected error occurred. Please try again.'}
      </ErrorMessage>
      <RetryButton onClick={reset}>Try again</RetryButton>
    </ErrorContainer>
  );
} 